$primary: #e24929;
$secondary: #5d4038;
$line-height-base: 2;

// Reset + Grid + Containers
@import "../node_modules/bootstrap/scss/bootstrap";

// Custom
@import "stylesheets/typography";

html,
body {
  background: #eeefe1;
}

body {
  overflow-y: scroll;
}

/**
 * Header
 */
.navbar {
  background: white;
  border-bottom: 1px solid $gray-400;

  .navbar-brand {
    margin-right: 0;
  }

  svg {
    height: 40px;
  }
}

/**
 * Sub-Navigation
 */
.nav-subnav {
  position: relative;
  z-index: 2;
  overflow-y: hidden;

  .nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .nav-link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.9rem;
    font-weight: bold;
    color: $secondary;
    transition: box-shadow 125ms ease;

    &.active {
      box-shadow: 0px -4px 0 -1px $secondary inset;
    }
  }
}

// Footer
.footer {
  padding: 48px 0;
}

// Page Sections
.section {
  margin: 0 auto 48px;
  max-width: 450px;

  &.fluid {
    max-width: 100%;
  }
}

//
// Override Bootstrap
//

/**
 * Bootstrap: Buttons
 */
.btn-primary {
  --bs-btn-color: var(--bs-white);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-btn-hover-color);
}

/*
 * Bootstrap: Cards
 */
.card-image {
  max-height: 225px;
  overflow: hidden;

  &.uncapped {
    max-height: none;
  }
}

/*
 * Bootstrap Utility Margins
 */
.mt-4-reverse {
  margin-top: -1.5rem !important;
}
