h4,
h5,
h6,
body {
  font-family: "Poppins", sans-serif;
}

small,
.small {
  font-size: 0.675em;
}

h4,
h5,
h6 {
  font-weight: 600;
}

.btn,
h1,
h2,
h3 strong {
  font-family: "Paytone One", sans-serif;
}
